import bn from 'bignumber.js'
import useBalance from '@pig-casino/hooks/useBalance'

import styled from 'styled-components'
import { Spin } from 'antd'
import IconCoin from '@pig-common/components/Icon/IconCoin'
import IconCoinBonus from '@pig-common/components/Icon/IconCoinBonus'

const HeaderCreditStyled = {
  Container: styled.div`
    display: flex;
    min-width: 8ch;
    align-items: flex-end;
    flex-direction: column;
    flex-wrap: wrap;
    align-content: flex-end;
    height: 100%;
    gap: 4px;
    padding: 6px 4px 6px 6px;
    border-right: 1px solid #03030320;
  `,
  Currency: styled.div<{ disabled?: boolean }>`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    column-gap: 2px;
    font-size: 20px;
    font-weight: 500;
    line-height: 1.2ch;
    opacity: ${({ disabled }) => (disabled ? '0.3' : '1')};
  `,
}

export default function BalanceCredit() {
  const { balance, isLoading } = useBalance({})
  const { balanceAmount, coinAmount } = balance
  return (
    <HeaderCreditStyled.Container data-testid="header__navbar--total-amount">
      {isLoading ? (
        <Spin />
      ) : (
        <>
          <HeaderCreditStyled.Currency>
            {bn(balanceAmount || '0').toFormat(2)}
            <IconCoin />
          </HeaderCreditStyled.Currency>
          <HeaderCreditStyled.Currency disabled>
            {bn(coinAmount || '0').toFormat(2)}
            <IconCoinBonus />
          </HeaderCreditStyled.Currency>
        </>
      )}
    </HeaderCreditStyled.Container>
  )
}
