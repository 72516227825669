const IconCoin = ({ size = 16 }: { size?: number }): JSX.Element => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={'0 0 14 14'}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.00243 14.0075C10.8642 14.0075 13.9948 10.8728 13.9948 7.00592C13.9948 3.13909 10.8642 0.00439453 7.00243 0.00439453C3.14062 0.00439453 0.0100098 3.13909 0.0100098 7.00592C0.0100098 10.8728 3.14062 14.0075 7.00243 14.0075Z"
        fill="url(#paint0_linear_4979_10862)"
      />
      <path
        opacity="0.4"
        d="M11.5803 -0.994707L-1.08661 11.7876L2.42518 15.3314L15.0921 2.54906L11.5803 -0.994707Z"
        fill="white"
      />
      <path
        d="M7.00304 12.4165C9.99381 12.4165 12.4183 9.96991 12.4183 6.9519C12.4183 3.93389 9.99381 1.4873 7.00304 1.4873C4.01227 1.4873 1.58777 3.93389 1.58777 6.9519C1.58777 9.96991 4.01227 12.4165 7.00304 12.4165Z"
        fill="#F28500"
      />
      <path
        d="M6.52365 3.95899V3.22559H7.26813V3.97098C7.36946 3.9748 7.47232 3.98118 7.57593 3.9901C7.67954 3.99903 7.77961 4.01229 7.87614 4.02988C7.97293 4.04773 8.06491 4.07043 8.15185 4.09797C8.23878 4.12551 8.31636 4.15815 8.38484 4.19564C8.49022 4.25863 8.59156 4.33258 8.68809 4.4175C8.78488 4.50216 8.87029 4.59906 8.94459 4.70719C9.01889 4.81557 9.07802 4.93491 9.12199 5.06497C9.16596 5.19502 9.18769 5.33706 9.18769 5.49109C9.18769 5.62114 9.17203 5.7438 9.14094 5.85932C9.10961 5.97458 9.06564 6.08118 9.00878 6.17885C8.95192 6.27651 8.8852 6.36526 8.80813 6.44507C8.73105 6.52489 8.64538 6.59451 8.55188 6.65367C8.68253 6.71666 8.80282 6.79418 8.91224 6.88599C9.02167 6.97753 9.11542 7.08081 9.19351 7.19506C9.27159 7.30956 9.33224 7.43324 9.3752 7.56609C9.41842 7.69921 9.4399 7.8387 9.4399 7.98456C9.4399 8.13043 9.42018 8.27604 9.38101 8.42088C9.3421 8.56573 9.28398 8.70343 9.20665 8.83349C9.12957 8.96354 9.03379 9.08493 8.91957 9.19739C8.80535 9.30985 8.67343 9.40726 8.52408 9.49014C8.37448 9.57302 8.20795 9.63753 8.02448 9.68394C7.84076 9.73036 7.64037 9.75331 7.42354 9.75331H7.26813V10.6081H6.52365V9.75331H4.56567V3.95899H6.52365ZM6.52365 6.32216V4.96755H5.8378V6.32216H6.52365ZM6.52365 8.74475V7.2481H5.8378V8.74475H6.52365ZM7.92769 5.63313C7.92769 5.52271 7.91051 5.42963 7.87639 5.35364C7.84228 5.2779 7.79578 5.21517 7.73715 5.16569C7.67852 5.11648 7.60903 5.07848 7.52917 5.05171C7.44881 5.02493 7.36188 5.00606 7.26838 4.99407V6.29844C7.50289 6.26096 7.67145 6.18369 7.77405 6.06613C7.87639 5.94934 7.92769 5.80475 7.92769 5.63313ZM8.16802 7.96085C8.16802 7.84252 8.1425 7.74052 8.0917 7.65484C8.04065 7.5689 7.97343 7.49775 7.88928 7.44037C7.80538 7.38325 7.7091 7.33939 7.60069 7.30879C7.49203 7.27819 7.38109 7.25804 7.26787 7.2481V8.74475H7.31488C7.45361 8.74475 7.57593 8.72486 7.68257 8.68558C7.78921 8.64631 7.87791 8.59149 7.94943 8.52136C8.02094 8.45123 8.07502 8.36835 8.11217 8.27298C8.14957 8.17735 8.16802 8.07331 8.16802 7.96085Z"
        fill="url(#paint1_linear_4979_10862)"
      />
      <path
        d="M7.00305 12.4635C3.97208 12.4635 1.50641 9.97517 1.50641 6.91659C1.50641 3.85801 3.97182 1.36963 7.00305 1.36963C10.0343 1.36963 12.4997 3.85801 12.4997 6.91659C12.4997 9.97517 10.034 12.4635 7.00305 12.4635ZM7.00305 1.53436C4.06204 1.53436 1.6694 3.94879 1.6694 6.91659C1.6694 9.88438 4.06204 12.2988 7.00305 12.2988C9.94406 12.2988 12.3367 9.88438 12.3367 6.91659C12.3367 3.94879 9.94406 1.53436 7.00305 1.53436Z"
        fill="url(#paint2_linear_4979_10862)"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4979_10862"
          x1="0.00995417"
          y1="7.00597"
          x2="13.9948"
          y2="7.00597"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCF0A" />
          <stop offset="1" stopColor="#FBA505" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_4979_10862"
          x1="-0.54184"
          y1="6.91689"
          x2="28.7433"
          y2="6.91689"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCF0A" />
          <stop offset="1" stopColor="#FBA505" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_4979_10862"
          x1="10.6636"
          y1="12.3554"
          x2="3.20105"
          y2="1.46708"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#FBCF0A" />
          <stop offset="1" stopColor="#FBA505" />
        </linearGradient>
      </defs>
    </svg>
  )
}

export default IconCoin
