import { Image, ProfileAvatar, ProfileAvatarTypes } from '@pig-frontend/uikit'
import { UserStar } from '@pig-common/components/User'
import { GuildInfo } from '@pig-common/types/Guild.type'
import { IVIPStatusData, IVIPInboxData } from '@pig-common/types/Vip.type'
import isEmpty from 'lodash/isEmpty'
import styles from './index.module.scss'

export default function UserAvatar({
  onClick,
  avatar,
  avatarUrl,
  guild,
  size = 44,
  vip,
}: {
  onClick?: (userAvatar: any) => void
  avatar?: ProfileAvatarTypes | string
  avatarUrl?: string
  size?: number
  shortProfile?: JSX.Element
  guild?: GuildInfo
  vip?: IVIPStatusData & IVIPInboxData
}) {
  const openShortProfile = () => {
    if (onClick) {
      onClick({ onClick, avatar, avatarUrl, guild, size, vip })
    }
  }

  return (
    <div
      role="button"
      aria-hidden
      onClick={openShortProfile}
      className={styles.container}
    >
      <ProfileAvatar
        name={avatar as ProfileAvatarTypes}
        imgUrl={avatarUrl}
        height={size}
        width={size}
        className={styles.avatar}
        {...(!isEmpty(vip) && {
          vip: {
            tier: vip?.userTier || vip?.vipTier,
            level: vip?.userVipLevel || vip?.vipLevel,
          },
        })}
      />
      {vip && (
        <div className={styles.vip}>
          <UserStar progress={vip?.userStar || vip?.vipStar} />
        </div>
      )}
      {guild?.iconUrl && (
        <Image
          src={guild?.iconUrl}
          className={styles.guild}
          height={size}
          width={size}
          alt="profile-avatar-guild-icon"
        />
      )}
    </div>
  )
}
