/** Legacy env from pigspin */

export const API_URL = process.env.NEXT_PUBLIC_API_BASE_URL
export const BO_API_URL = process.env.NEXT_PUBLIC_API_BO

export const PIGSPIN_API_URL =
  process.env.NEXT_PUBLIC_PIGSPIN_API_BASE_URL ||
  process.env.NEXT_PUBLIC_API_BASE_URL
export const QR_PAYMENT_API_URL = process.env.NEXT_PUBLIC_API_BASE_URL_V2

export const SOCKET_ENDPOINT =
  process.env.NEXT_PUBLIC_SOCKET_ENDPOINT || 'https://api.pigspin.dev'

export const PIG_WEB_URL =
  process.env.NEXT_PUBLIC_PIGSPIN_URL || 'https://staging.pigspin.dev'
export const WEB_URL = process.env.NEXT_PUBLIC_WEB_URL || 'https://PIGSPIN.com/'
export const CLOUD_STORAGE_CDN =
  process.env.NEXT_PUBLIC_CLOUD_STORAGE_CDN ||
  'https://assets-piguniverse.jklmn23456.com'
export const CLOUD_SHARED_STORAGE_CDN =
  process.env.NEXT_PUBLIC_CLOUD_SHARED_STORAGE_CDN || 'https://assets.afufc.com'

export const IMAGE_FOLDER_PATH = '/images'

export const SHARED_ASSET_URL = `${CLOUD_STORAGE_CDN}/shared-assets`
export const ASSET_URL = `${CLOUD_STORAGE_CDN}/${
  process.env.NEXT_PUBLIC_ASSET_FOLDER || 'pigspin-assets'
}`

export const PRODUCT_NAME = process.env.NEXT_PUBLIC_PRODUCT_NAME || 'PIGSPIN'
export const PRODUCT_LINE_ACCOUNT =
  process.env.NEXT_PUBLIC_PRODUCT_LINE_ACCOUNT || 'PIGSPIN'
export const PRODUCT_EMAIL =
  process.env.NEXT_PUBLIC_PRODUCT_EMAIL || 'support@PIGSPIN.com'
export const LINE_CONTACT_URL =
  process.env.NEXT_PUBLIC_LINE_URL || 'https://line.me/R/ti/p/@pigsp'
export const APP_GAME_NAME = process.env.NEXT_PUBLIC_GAME_NAME || 'PIGSPIN'
export const FACEBOOK_OFFICIAL_URL =
  process.env.NEXT_PUBLIC_FACEBOOK_OFFICIAL_URL ||
  'https://www.facebook.com/PIG-SPIN-105240521637668/?view_public_for=105240521637668'
export const TWITTER_OFFICIAL_URL =
  process.env.NEXT_PUBLIC_TWITTER_OFFICIAL_URL || 'https://twitter.com/PigspinS'

export const GA_TRACKING_ID = process.env.NEXT_PUBLIC_GA_ID
export const GTM_TRACKING_ID = process.env.NEXT_PUBLIC_GTM_ID
export const OPT_TRACKING_ID = process.env.NEXT_PUBLIC_OPT_ID
export const PROPELLER_ADS_PARTNER_ID =
  process.env.NEXT_PUBLIC_PROPELLER_ADS_PARTNER_ID || ''

export const LINE_CHANNEL_ID = process.env.NEXT_PUBLIC_LINE_CHANNEL_ID || ''
// !!! Dont make it PUBLIC
export const LINE_CLIENT_SECRET = process.env.LINE_CLIENT_SECRET || ''

// NOTE : Bank Asset Data
export const BANK_IMAGE_PATH = `${CLOUD_SHARED_STORAGE_CDN}/bank-icon/logo`
export const BANK_APP_IMAGE_PATH = `${CLOUD_SHARED_STORAGE_CDN}/bank-icon/app`

export const CURRENT_ENV = process.env.ENV_TYPE || ''

// NOTE : Chat Key (Pubnub chat)
export const PUBNUB_PUBLIC_KEY = process.env.NEXT_PUBLIC_PUBNUB_PUBLIC_KEY || ''
export const PUBNUB_SUBSCRIBE_KEY =
  process.env.NEXT_PUBLIC_PUBNUB_SUBSCRIBE_KEY || ''
