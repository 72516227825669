// images
import emptyWalletImage from '@pig-common/public/images/wallet/empty-wallet.png'
import withdrawSuccessImage from '@pig-common/public/images/wallet/withdraw-success.png'
import noResultImage from '@pig-common/public/images/no-result.png'
import affiliateNoResultImage from '@pig-common/public/images/affiliate/no-result.png'
import minimizeLogoImage from '@pig-common/public/images/logo/animated/logo.webp'
import coinIcon from '@pig-common/public/images/coin.png'
import refreshIcon from '@pig-common/public/images/refresh-icon.png'
import guildBannerDefaultImage from '@pig-common/public/images/guild/banner-default.jpeg'
import dailySpinRouletteArrowImage from '@pig-common/public/images/daily-spin-roulette-arrow.png'
import dailySpinRouletteHandlerImage from '@pig-common/public/images/daily-spin-roulette-handler.png'
import dailySpinRouletteLight1Image from '@pig-common/public/images/daily-spin-roulette-light-1.png'
import dailySpinRouletteLight2Image from '@pig-common/public/images/daily-spin-roulette-light-2.png'
import dailySpinRouletteImage from '@pig-common/public/images/daily-spin-roulette.png'
import ticketImage from '@pig-common/public/images/ticket.png'
import errorNoticeImage from '@pig-common/public/images/error.png'
import pigHuntWinnerBanner from '@pig-common/public/images/coin.png'

// icons
import { ReactComponent as TransferDepositImage } from '@pig-common/public/images/transfer-deposit.svg'
import { ReactComponent as QRDepositImage } from '@pig-common/public/images/qr-deposit.svg'
import { ReactComponent as TruemoneyDepositImage } from '@pig-common/public/images/truemoney-deposit.svg'
import { ReactComponent as MaintenanceImage } from '@pig-common/public/images/maintenance.svg'
import { ReactComponent as ChatIcon } from '@pig-common/public/images/chat-icon.svg'
import { ReactComponent as GuildRoleOwnerImage } from '@pig-common/public/images/guild/role-owner.svg'
import { ReactComponent as GuildRoleAdminImage } from '@pig-common/public/images/guild/role-admin.svg'
import { ReactComponent as GuildRoleMemberImage } from '@pig-common/public/images/guild/role-member.svg'
import { ReactComponent as DailySpinFloatingButtonImage } from '@pig-common/public/images/daily-spin-floating-button.svg'
import { ReactComponent as StarIcon } from '@pig-common/public/images/loyalty/vip/star/star.svg'
import { ReactComponent as StarGreyIcon } from '@pig-common/public/images/loyalty/vip/star/star-grey.svg'

export {
  // images
  emptyWalletImage,
  withdrawSuccessImage,
  minimizeLogoImage,
  coinIcon,
  refreshIcon,
  guildBannerDefaultImage,
  dailySpinRouletteHandlerImage,
  dailySpinRouletteImage,
  dailySpinRouletteArrowImage,
  dailySpinRouletteLight1Image,
  dailySpinRouletteLight2Image,
  ticketImage,
  errorNoticeImage,
  pigHuntWinnerBanner,

  // Icons
  TransferDepositImage,
  QRDepositImage,
  TruemoneyDepositImage,
  affiliateNoResultImage,
  noResultImage,
  MaintenanceImage,
  ChatIcon,
  GuildRoleOwnerImage,
  GuildRoleAdminImage,
  GuildRoleMemberImage,
  DailySpinFloatingButtonImage,
  StarIcon,
  StarGreyIcon,
}
