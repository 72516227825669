import { authStateCommon } from '@pig-common/recoils'
import { useQuery } from '@tanstack/react-query'
import { balanceApi } from '@pig-casino/api'
import { useRecoilValue } from 'recoil'
import { useEffect } from 'react'

interface IGetBalanceType {
  select?: (data: any) => any
  enabled?: boolean
  refetchInterval?: number
}
interface IGetBalance {
  total: number
  balanceAmount: number
  coinAmount: number
}

const useBalance = ({
  select,
  enabled,
  refetchInterval,
}: IGetBalanceType | undefined): {
  balance: IGetBalance
  isLoading: boolean
  refetchBalance: () => void
} => {
  const isAuthenticated = useRecoilValue(authStateCommon.isAuthenState)
  const { userUID } = useRecoilValue(authStateCommon.authState)
  const {
    data: balance,
    isFetching: isLoading,
    refetch: refetchBalance,
  } = useQuery({
    queryKey: [balanceApi.key.get, userUID],
    queryFn: async () => {
      const response = await balanceApi.get({ userUID })
      try {
        const result = {
          total:
            response?.data?.balanceAmount + response?.data?.coinAmount || 0,
          balanceAmount: response?.data?.balanceAmount || 0,
          coinAmount: response?.data?.coinAmount || 0,
        }
        return result
      } catch {
        return {
          total: 0,
          balanceAmount: 0,
          coinAmount: 0,
        }
      }
    },
    enabled: !isAuthenticated ? false : enabled,
    select: (data) => (select ? select(data) : data) || {},
    refetchInterval,
    initialData: {
      total: 0,
      balanceAmount: 0,
      coinAmount: 0,
    },
  })
  useEffect(() => {
    refetchBalance()
  }, [userUID])
  return {
    balance,
    isLoading,
    refetchBalance,
  }
}
export default useBalance
